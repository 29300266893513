var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"qZ3a_NyOtEFUxpjtaI_th"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/static/08685e44";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole as CaptureConsoleIntegration, RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.LOCAL_DEVELOPMENT !== 'true') {
    Sentry.init({
        enabled: process.env.LOCAL_DEVELOPMENT !== 'true',
        dsn:
            SENTRY_DSN ||
            'https://47d381582e1340509091937f552bb732@o956104.ingest.sentry.io/4504752104144896',
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 0.3,
        release: process.env.RELEASE_NAME,
        environment: process.env.NEXT_PUBLIC_EXEC_ENV,
        integrations: [
            new RewriteFrames(
                {
                    prefix: `https://${process.env.NEXT_PUBLIC_HOST}${process.env.STATICS_PATH}`,
                }
            ),
            new Integrations.BrowserTracing(),
            new CaptureConsoleIntegration({
                levels: ['error']
            })
        ],
        ignoreErrors: [
            /Email\+is\+required/g,
            /Failed\+to\+verify\+invitation\+assignment/g,
            /Loading chunk \d+ failed/g,
            'ResizeObserver loop limit exceeded',
            'Non-Error promise rejection captured with keys: message',
            /^\[J-ERROR] Failed to send/gim,
            /canceled/g,
            'ExceptionType: CanceledError',
            // nextjs throws error to terminate rendering of the route segment: https://beta.nextjs.org/docs/api-reference/redirect#redirect
            'NEXT_REDIRECT'
        ]
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
